.login-page {
    display: flex;
    font-family: "Catamaran", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 800;

    .exit-btn-mobile {
        display: none;
    }

    .login-form {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 22px 32px;
        gap: 36px;
        overflow-y: scroll;
        scrollbar-width: none;

        .captcha-msg {
            text-align: center;
            color: $nav-group-svg;
            font-size: 13px;
            font-weight: 500;
        }

        .form-container {
            width: fit-content;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 50%;

            h1 {
                font-size: 25px;
                height: 34px;
                font-weight: 800;
            }

            h2 {
                font-size: 15px;
                font-weight: 800;

                a {
                    color: $login-link;

                    &:hover {
                        color: $login-link-hover;
                        text-decoration: underline;
                    }
                }
            }

            .styled-header {
                font-weight: 800;
            }

            form {
                display: flex;
                flex-direction: column;
                gap: 12px;

                a,
                label {
                    color: $nav-group-svg;
                    font-size: 15px;
                    font-weight: 700;
                    margin-bottom: 3px;
                }

                a {
                    width: fit-content;
                    margin: 0;
                    font-size: 13px;
                    font-weight: 800;

                    &:hover {
                        color: $main-color;
                        text-decoration: underline;
                    }
                }

                .login-show-hide-pw {
                    position: relative;
                    height: 30px;
                    margin-top: -43px;
                    width: 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    left: 90%;

                    .svg-icon {
                        width: 22.5px;
                        height: 18px;

                        path {
                            fill: $secondary-color;
                        }

                        &:hover {
                            cursor: pointer;

                            path {
                                fill: $main-color;
                            }
                        }
                    }
                }

                .social-btns-continer {
                    display: flex;
                    gap: 16px;

                    .social-btn-container {
                        background-color: $hover-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 64px;
                        width: 33.33%;
                        height: 36px;
                        padding: 9px;
                        font-size: 13px;
                        border-radius: 12px;
                        gap: 4px;
                        cursor: pointer;
                        transition: all .2s;

                        .svg-icon {
                            width: 16px;
                            height: 16px;
                            margin-bottom: 3px;
                        }

                        &:hover {
                            background-color: $secondary-hover;
                        }
                    }
                }
            }

            .input-container {
                display: flex;
                flex-direction: column;
            }

            .error-msg{
                color: $error-color;
                font-size: 13px;
                font-weight: 600;
                margin: -5px 0px;
            }
        }

        img {
            width: 209px;
        }
    }

    .hero-container {
        width: 50%;

        .exit-btn {
            position: absolute;
            top: 20px;
            right: 30px;
        }

        img {
            width: 50vw;
            height: 100vh;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 1400px) {
    .login-page {
        .login-form {
            .login-show-hide-pw {
                left: 86%;
            }
            .form-container {
                width: 80% !important;
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .login-page {
        .login-form {
            form {
                .social-btn-container {
                    .social-title {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .login-page {
        .hero-container {
            display: none;
        }

        .exit-btn-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 16px;
            top: 16px;
            width: 36px;
            height: 36px;
            border-radius: 12px;
            background-color: $hover-color;
            transition: all .2s;
            cursor: pointer;

            &:hover {
                background-color: $secondary-hover;
            }
        }

        .login-form {
            padding: 16px;
            width: 100% !important;

            img {
                width: 140px;
            }

            .captcha-msg {
                margin-top: auto;
            }

            .form-container {
                width: 100% !important;

                form {
                    .login-show-hide-pw {
                        left: 88%;
                    }

                    .social-btns-continer {
                        flex-direction: column;

                        .social-btn-container {
                            width: 100% !important;

                            .social-title {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

}