$main-color: #fff;
$primary-color: #fff;
$secondary-color: #b4a6e2;

$main-bgc: #191939;
$main-darker-bgc: #090c1d;

$hover-color: #2c2852;
$secondary-hover: #463e7a;
$primary-hover: #7b6cb9;
$nav-btn-main-hover: #5a528f;

$placeholder-color: #7b6cb9;

$text-color: #D9D1F4;
$secondary-text: #B4A6E2;

$nav-group-svg: #9789CD;

$lang-active-bg: #7640ec;
$chevron-btn-bg: #6d62a2;

$rank-border: #7841ee;


$gold-color: #ffdb00;

$login-link: #a37ef2;
$login-link-hover: #E4D8FD;

$error-color: #F11F5E;