.partners{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 36px;
    margin-bottom: 36px;


    h2 {
        width: 100%;
        font-family: "Catamaran", "Roboto", sans-serif;
        font-size: 15px;
        font-weight: 800;
        text-wrap: nowrap;
    }

    div {
        height: 96px;
        padding: 24px;
        background: $main-bgc;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s;

        img {
            height: 32px;
        }
        .interact-img{
            height: 40px;
        }
    }

}

@media screen and (max-width: 800px) {
    .partners {
        margin-top: 24px;
        margin-bottom: 24px;
    }

}