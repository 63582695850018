.deposit-booster-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 12px;
    background-color: $main-bgc;
    font-family: "Catamaran", "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    gap: 8px;
    cursor: default;

    .header {
        font-size: 64px;
        font-weight: 900;
        background-image: url('../../images/bonus/deposit-booster-hero.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        max-height: 490px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        color: $gold-color;

        img {
            transition: all .5s;
            position: relative;
            bottom: -40px;
            left: 180px;
        }

        &:hover {
            img {
                bottom: 38px;
                left: 240px;
            }
        }
    }

    .roowards-head {
        background-image: url('../../images/bonus/roowards-chest.jpg');
        min-height: 446px;
    }

    .text-description {
        color: $text-color;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: $gold-color;
            font-size: 24px;
            font-weight: 800;
        }

        ul {
            padding: 0px;
            text-align: left;

            li{
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .text-description.roowards{
        flex-direction: column;
    }

    .terms {
        margin-top: 10px;
        gap: 6px;

        a {
            color: $text-color;
            transition: all .2s;

            &:hover {
                text-decoration: underline;
                color: $main-color;
            }
        }
    }

    .img-container {
        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 640px) {
    .deposit-booster-container {
        .header {
            max-height: 239px;
            font-size: 36px;

            img {
                width: 180px;
                left: 80px;
                bottom: -20px;
            }

            &:hover {
                img {
                    bottom: 18px;
                    left: 140px;
                }
            }
        }

        .text-description {
            ul {
                max-width: 90%;
            }
        }
    }

}