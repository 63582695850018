.footer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 70px;

    .footer-hr {
        height: 1px;
        border-top: 2px solid $main-bgc;
    }

}

@media screen and (max-width: 800px) {
    .footer {
        margin-bottom: 120px;
    }

}