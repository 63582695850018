.footer-links-container {
    font-family: "Catamaran", "Roboto", sans-serif;
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    margin-top: 33px;
}

.footer-links-col {
    width: 160px;
    font-family: "Catamaran", "Roboto", sans-serif;


    .footer-links-col-title {
        font-weight: 800;
        font-size: 14px;
    }

    .footer-links-col-links {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        gap: 12px;
        margin-top: 10px;

        a {
            color: $nav-group-svg !important;
            text-wrap: nowrap;
            transition: all .2s;

            &:hover {
                color: $main-color !important;
                text-decoration: underline;
            }
        }
    }

    .footer-links-col-links.social {
        display: grid;
        justify-items: baseline;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        width: 120px;

        .svg-icon {
            width: 24px;
            height: 24px;

            path {
                transition: all .2s;
                fill: $nav-group-svg;
            }
        }

        a {
            &:hover {
                .svg-icon {
                    path {
                        fill: $main-color;
                    }
                }
            }
        }

    }
}


.dropdown-menu {
    display: flex;
    flex-direction: column;
    font-family: "Catamaran", "Roboto", sans-serif;
    margin-top: 24px;
    gap: 16px;

    .header-title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        font-weight: 700;
        font-size: 16px;
    }

    .dropdown-items {
        display: flex;
        flex-direction: column;
        gap: 11px;
        list-style: none;
        padding: 0;
        overflow: hidden;
        max-height: 0;
        transition: all 0.2s ease-in-out;

        a {
            color: $nav-group-svg;
            font-size: 15px;

            &:hover {
                color: $main-color;
                text-decoration: underline;
            }
        }
    }



    .dropdown-header.active .dropdown-items {
        margin-top: 12px;
        max-height: 600px;
        display: flex;
        flex-direction: column;
        gap: 11px;

    }



    .dropdown-header.active {
        .svg-icon {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 800px) {

    .footer-links-col {
        margin-top: 24px;

        .footer-links-col-links.social {
            display: flex;
            width: 100%;
            flex-direction: row;
        }
    }

}