.accepted-currencies {
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    h2{
      width: 100%;  
      font-family: "Catamaran", "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 800;
      text-wrap: nowrap;
    }

    .currencie-container {
        background-color: $main-bgc;
        border-radius: 12px;
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 4px;
        padding: 12px;
        height: 44px;
        justify-content: center;

        .svg-icon {
            width: 16px;
            height: 16px;
            margin-bottom: 3px;
        }

        p {
            margin: 0;
            font-family: "Catamaran", "Roboto", sans-serif;
            font-size: 14px;
            font-weight: 800;
            text-wrap: nowrap;
        }
    }

}

@media screen and (max-width: 800px) {
    .accepted-currencies {
        margin-top: 24px;
        margin-bottom: 24px;
    }

}