.top-nav-user-container {
    display: flex;
    align-items: center;
    gap: 8px;


    .clickable {
        background-color: $hover-color;
        border: 4px solid $main-darker-bgc;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        transition: all .3s;
        cursor: pointer;

        &:hover {
            background-color: $secondary-hover;
        }
    }

    .clickable-rank{
        border: none;
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
    }

    .user-container {
        position: relative;
        width: 176px;
        background-color: $main-darker-bgc;
        border-radius: 16px;

        .exit-bg {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 98;
        }

        .user-menu {
            z-index: 99;
            position: absolute;
            margin-top: 4px;
            right: 0px;
            background-color: $main-bgc;
            padding: 16px;
            border-radius: 12px;
            border: 4px solid $main-darker-bgc;
            font-family: "Catamaran", Roboto, sans-serif;
            font-size: 13px;
            font-weight: 800;
            color: $text-color;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-top: 6px;

            .user-name {
                display: none;
                gap: 6px;
                color: $nav-group-svg;
                margin-bottom: -12px;

                span {
                    color: $main-color;
                    font-size: 14px;
                }
            }

            .menu-item {
                display: flex;
                align-items: center;
                width: 158px;
                height: 36px;
                gap: 8px;
                background-color: $hover-color;
                padding: 6px 8px 4px;
                color: $text-color !important;
                transition: background-color .2s;
                cursor: pointer;

                .svg-icon {
                    width: 24px;
                    height: 24px;

                    path {
                        transition: all .2s;
                        fill: $nav-group-svg;
                    }
                }

                &:hover {
                    background-color: $secondary-hover;

                    .svg-icon {
                        path {
                            fill: $text-color;
                        }
                    }
                }

            }

            .bell {
                display: none;

                .svg-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .first {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }

            .last {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }

        &:hover{
            .clickable {
                .chevron {
                    background-color: $hover-color;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .top-nav-user-container {

        .clickable-rank{
            background-color: $hover-color;
            border: 4px solid $main-darker-bgc;
            &:hover {
                background-color: $secondary-hover;
            }
        }

        .bell {
            display: none;
        }

        .user-container {
            width: unset;

            .user-menu {
                margin-top: 10px;
                padding-top: 16px;

                .user-name {
                    display: flex;
                    margin-bottom: 0px;
                }

                .bell {
                    display: flex !important;
                }
            }
        }
    }
}