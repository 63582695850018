.table-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
    margin-bottom: 12px;

    .table-nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-nav-btn {
            height: 48px;
            width: 136px;
            padding: 12px 16px;
            font-size: 13px;
            color: $secondary-text;
            text-align: center;
            cursor: pointer;
            transition: all .2s;

            &:hover {
                color: $main-color;
                font-weight: 700;
            }
        }

        .active {
            color: $main-color;
            font-weight: 700;
            border-bottom: 2px solid $lang-active-bg;
        }

    }

    .bet-time{
        display: flex;
        max-width: 90px;
        min-width: 107px;
        align-items: center;
        text-wrap: nowrap;
    }


}

.table {
    width: 100%;
    border-collapse: collapse;

    .table-alin-right {
        text-align: right;
    }
}

.table-head {
    display: flex;
    background-color: $hover-color;
    z-index: 1;
    position: relative;
    margin-bottom: 2px;
    height: 44px;
    align-items: center;
    font-size: 13.2px;

    .pay-out {
        padding-right: 28px;
    }
}

.table-head div {
    flex: 1;
    padding: 10px;
    font-weight: bold;
    text-align: center;
}

.table-body .table-row {
    display: flex;
    height: 38px;
    overflow: hidden;
    background-color: $main-bgc;

    .table-alin-right {
        text-align: right;
    }

    .pay-out {
        padding-right: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .wager {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;

        .svg-icon {
            width: 16px;
            height: 16px;
        }
    }

    .lose {
        color: $placeholder-color;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .win {
        color: #3dd600;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.table-body .table-row a,
.table-body .table-row div {
    flex: 1;
    
    text-align: center;
    font-size: 13px;
    font-weight: 700;
}

.table-user-name {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
    padding-left: 64px;

    .un-hidden {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 4px;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }


    .table-hidden {
        color: $placeholder-color;
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 4px;
        font-weight: 400;

        cursor: default;

        .svg-icon {

            width: 16px;
            height: 16px;

            path {
                fill: $placeholder-color;
            }
        }
    }

    .rank-img {
        width: 24px;
        height: 16px;
    }
}


.table-head .game,
.table-body .table-row .game {
    text-align: left;
    padding-left: 28px;
    height: -webkit-fill-available;
    max-width: 198.83px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .game-title {
        text-align: left;
        width: fit-content;
        width: 1px;
        padding-left: 0px;
        position: absolute;
        left: 71px;
        font-weight: 500;
    }

    .game-img-container {
        width: 32px !important;
        padding: 0px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 32px;
            height: 32px;
            border-radius: 4px;

            &:hover {
                border: 2px solid;
                cursor: pointer;
            }
        }
    }
}


.table-body {
    position: relative;
    max-height: 418px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border: none;
    }
}

.bottom-smooth {
    width: 100%;
    height: 16px;
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: $main-bgc;

    &::before {
        content: "";
        position: absolute;
        bottom: 14px;
        left: 0;
        width: 100%;
        height: 350%;
        background: linear-gradient(to bottom, rgba(25, 25, 57, 0), $main-bgc);
    }
}

@media screen and (max-width: 1390px) {
    .table-head {
        .pay-out {
            max-width: 198.83px;
        }

        .table-username-head{
            text-align: left;
            padding-left: 64px;
        }
    }

    .table-body .table-row {
        .pay-out {
            max-width: 198.83px;
        }
    }

}

@media screen and (max-width: 600px) {
    .table-head {
        .pay-out {
            max-width: unset;
        }
    }

    .table-body .table-row {
        .pay-out {
            max-width: unset;
        }
    }

}