* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: $main-bgc;
    color: $main-color;
    overflow: auto;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: $main-color;
    cursor: pointer;
}


.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.styled-btn {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Catamaran", Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.75;
    min-width: 64px;
    width: 100%;
    text-align: center;
    text-transform: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    letter-spacing: normal;
    text-shadow: rgb(197, 106, 24) 0px 2px 0px, rgb(197, 106, 24) 0px -1px 0px, rgb(197, 106, 24) -1px 0px 0px, rgb(197, 106, 24) 1px 0px 0px;
    -webkit-text-stroke: 0.5px rgb(197, 106, 24);
    box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    transition: background-size 0.3s ease-in-out 0s;
    background: linear-gradient(420deg, rgb(255, 206, 0) 0%, rgb(255, 206, 0) 15%, rgb(238, 175, 14) 15%, rgb(238, 175, 14) 100%) left center / 100% 100% no-repeat;
    height: 48px;
    padding: 12px 23px 16px;

    &:hover {
        background-size: 750% 100%;
        box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    }

    &:disabled {
        background: rgb(238, 175, 14) !important;
        box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset !important;
        cursor: wait !important;
    }
}



.styled-btn-small {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Catamaran", Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.75;
    min-width: 64px;
    text-align: center;
    text-transform: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 12px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    text-shadow: rgb(197, 106, 24) 0px 2px 0px, rgb(197, 106, 24) 0px -1px 0px, rgb(197, 106, 24) -1px 0px 0px, rgb(197, 106, 24) 1px 0px 0px;
    -webkit-text-stroke: 0.1px rgb(197, 106, 24);
    box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    transition: background-size 0.3s ease-in-out 0s;
    background: linear-gradient(420deg, rgb(255, 206, 0) 0%, rgb(255, 206, 0) 49%, rgb(238, 175, 14) 49%, rgb(238, 175, 14) 100%) left center / 100% 100% no-repeat;
    padding: 6px 14px 10px;
    cursor: pointer;
    height: 36px;
    width: 80.86px;
    font-size: 12px;

    &:hover {
        background-size: 300% 100%;
        box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    }
}


.styled-btn-secondary {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.75;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-align: center;
    text-transform: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 12px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    letter-spacing: normal;
    background-color: rgb(44, 40, 82);
    padding: 9px 14px 11px;
    cursor: pointer;
    box-shadow: none !important;
    height: 36px;
    width: 64px;
    font-size: 12px;
    text-wrap: nowrap;

    &:hover {
        background-color: rgb(70, 62, 122);
    }
}


.styled-header {
    color: #FFF;
    width: 100%;
    position: relative;
    text-align: center;
    font-weight: 500;

    &:before {
        top: 50%;
        left: 0;
        width: calc(50% - 60px);
        height: 2px;
        content: " ";
        position: absolute;
        background: #2C2852;
    }

    &:after {
        top: 50%;
        right: 0;
        width: calc(50% - 60px);
        height: 2px;
        content: " ";
        position: absolute;
        background: #2C2852;
    }
}

.styled-input {
    box-sizing: content-box;
    animation-duration: 10ms;
    border: none;
    padding: 8px 12px;
    background-color: #2c2852;
    border-radius: 12px;
    height: 24px;
    font-size: 16px;
    border: 2px solid transparent;
    color: #ffffff;
    font-weight: 500;
    cursor: text;

    &::placeholder {
        color: $placeholder-color;
        font-weight: 700;
        font-size: 17px;
        font-family: "Catamaran", Roboto, sans-serif;
    }

    &:disabled {
        color: $placeholder-color;
        background-color: $secondary-hover;
        cursor: wait;
    }
}


.styled-input:hover {
    border-color: rgb(151, 137, 205) !important;
}


.styled-input:focus,
.styled-input:focus-visible {
    outline: none;
    border: 2px solid #7e40cf !important;
}

.error-input {
    border-color: $error-color;

    &:hover {
        border-color: $error-color !important;
    }

    &:disabled {
        border-color: $error-color !important;
        background-color: #2c2852 !important;
        cursor: not-allowed !important;
    }
}

.styled-btn-primery {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    width: 100%;
    text-align: center;
    text-transform: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    letter-spacing: normal;
    text-shadow: rgb(197, 106, 24) 0px 2px 0px, rgb(197, 106, 24) 0px -1px 0px, rgb(197, 106, 24) -1px 0px 0px, rgb(197, 106, 24) 1px 0px 0px;
    -webkit-text-stroke: 0.5px rgb(197, 106, 24);
    box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    transition: background-size 0.3s ease-in-out 0s;
    background: linear-gradient(420deg, rgb(255, 206, 0) 0%, rgb(255, 206, 0) 15%, rgb(238, 175, 14) 15%, rgb(238, 175, 14) 100%) left center / 100% 100% no-repeat;
    height: 48px;
    padding: 12px 23px 16px;
    font-family: Roboto, Arial, Helvetica, sans-serif;

    &:hover {
        background-size: 750% 100%;
        box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    }

    &:disabled {
        -webkit-text-stroke-color: rgb(25, 25, 57);
        background: rgb(44, 40, 82);
        color: rgb(255, 255, 255);
        box-shadow: rgb(35, 32, 66) 0px -3px 0px 0px inset, rgb(89, 81, 142) 0px 2px 0px 0px inset;
        text-shadow: rgb(25, 25, 57) 0px 1px 0px;
        cursor: not-allowed !important;
    }
}

.loader-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 400px;
    background-color: $main-darker-bgc;
    border-radius: 12px;

    .spinner {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: conic-gradient(#0000 1%, $lang-active-bg) content-box;
        mask: repeating-conic-gradient(#0000 0deg, #000 1deg 39deg, #0000 40deg 45deg),
            radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 calc(100% - 9px));
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
        animation: spinner 1s infinite steps(8);
    }

    .roo {
        position: absolute;
        width: 22px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
            fill: $lang-active-bg;
        }
    }
}