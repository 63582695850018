.loader-span {
    position: absolute;

    .spinnerV2 {
        display: block;
        position: absolute;
        top: 0px;
        left: -7px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: conic-gradient(#0000 1%, #c56a18) content-box;
        mask: repeating-conic-gradient(#0000 0deg, #000 1deg 39deg, #0000 40deg 45deg),
            radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 calc(100% - 4px));
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
        animation: spinnerV2 1s infinite steps(8);
    }

    svg {
        position: relative;
        left: -.2px;
        top: 4px;
    }
}

@keyframes spinnerV2 {
    to {
        transform: rotate(1turn);
    }
}