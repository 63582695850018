.awards-licenses {
    display: flex;
    gap: 16px;
    margin-top: 36px;
    margin-bottom: 36px;

    .awards {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        h2 {
            width: 100%;
            font-family: "Catamaran", "Roboto", sans-serif;
            font-size: 15px;
            font-weight: 800;
            text-wrap: nowrap;
        }

        a {
            height: 112px;
            padding: 16px;
            background: $main-bgc;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;

            img {
                width: 164px;
            }

            &:hover {
                background-color: $hover-color;
            }
        }

    }

    .licenses,.responsible-gambling {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        flex-direction: column;
        margin: auto;

        a {
            height: 112px;
            width: 112px;
            padding: 16px;
            background: $main-bgc;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;

            img {
                width: 73px;
                height: 73px;
            }

            &:hover {
                background-color: $hover-color;
            }
        }


        h2 {
            width: 100%;
            font-family: "Catamaran", "Roboto", sans-serif;
            font-size: 15px;
            font-weight: 800;
            text-wrap: nowrap;
        }


    }
}

@media screen and (max-width: 1006px) {
    .licenses,.responsible-gambling {
        min-width: 290px;
        flex-direction: row;
        margin: 0;
        a{
            max-width: min-content;
        }
    }
}

@media screen and (max-width: 800px) {
    .awards-licenses {
        flex-wrap: wrap;
        margin-top: 24px;
        margin-bottom: 24px;

        .awards {
            a {
                height: 96px;

                img {
                    width: 123px;
                    height: 64px;
                }
            }
        }

        .licenses,.responsible-gambling {
            margin-top: 30px;
            min-width: unset;
            flex-direction: row;
            margin: 0;

            a {
                height: 96px;
            }
        }
    }

}