.nav-chat {
    position: fixed;
    top: 0;
    right: 0;
    background-color: $main-darker-bgc;
    width: 100vw;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    gap: 10px;

    .nav-chat-header {
        background-color: $main-bgc;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 9px 16px;

        .close-btn {
            border: none;
            background-color: $hover-color;
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            transition: all .3s;
            border: 4px solid $main-darker-bgc;
            cursor: pointer;

            &:hover {
                background-color: $secondary-hover;
            }
        }
    }

    .nav-chat-msgs {
        .loader-chat {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .spinner {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: conic-gradient(#0000 1%, $lang-active-bg) content-box;
                mask: repeating-conic-gradient(#0000 0deg, #000 1deg 39deg, #0000 40deg 45deg),
                    radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 calc(100% - 9px));
                -webkit-mask-composite: destination-in;
                mask-composite: intersect;
                animation: spinner 1s infinite steps(8);
            }
            .roo {
                position: absolute;
                width: 22px;
                height: 50px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                path{
                    fill: $lang-active-bg;
                }
            }
        }
    }
    
}