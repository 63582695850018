.cashier-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    .svg-icon-cash {
        height: 18px;
        width: 20px;
    }

    .cashier {
        font-family: "Catamaran", "Roboto", sans-serif;
        background-color: $main-darker-bgc;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 608px;
        transform: translate(-50%, -50%);
        border-radius: 12px;
        cursor: default;
        z-index: 10;


        .cashier-header {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            .cashier-header-top {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: .8em;
                font-weight: 800;
                background: rgb(9, 12, 29);
                padding-right: 12px;
                padding-left: 32px;
                height: 44px;
                font-size: 14px;

                button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }
            }

            .cashier-header-bootom {
                display: flex;
                margin: 0px 4px;

                div {
                    color: $secondary-color;
                    width: 180px;
                    height: 36px;
                    font-size: 13px;
                    font-weight: 800;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: color .2s;
                    cursor: pointer;

                    &:hover {
                        color: $main-color;
                    }
                }

                .active {
                    border-bottom: 2px solid $lang-active-bg;
                    color: $main-color;
                    cursor: default !important;
                }
            }
        }

        .cashier-depo-main {
            background-color: $hover-color;
            margin: 0px 4px;
            margin-bottom: 4px;
            padding: 28px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;


            .hide-ripple {
                background-color: $main-bgc;
                z-index: 9;
                border-radius: 12px;
                padding: 16px;
                margin-bottom: 12px;

                .header {
                    font-size: 17px;
                    font-weight: 700;
                    line-height: 22px;
                }

                ul {
                    margin: 0;
                    margin-bottom: 8px;
                    margin-top: 4px;
                    padding: 0px 0px 0px 20px;

                    li {
                        list-style: numbers;
                        font-family: "Roboto", sans-serif;
                        font-size: 12px;
                        color: $secondary-color;
                        margin-top: 2px;
                    }
                }

                .text {
                    font-size: 13px;
                    font-weight: 700;
                    color: $secondary-color;

                    a {
                        color: $text-color;
                        transition: all .2s;

                        &:hover {
                            color: $main-color;
                            text-decoration: underline;
                        }
                    }
                }

                button {
                    margin-top: 8px;
                    min-width: 100%;
                }
            }

            .cashier-depo-main-title {
                font-weight: 700;
                margin-bottom: 16px;

                .cashier-depo-main-subtitle {
                    color: $text-color;
                    font-size: 13px;
                }
            }



            .coin-select-container {
                background-color: $main-bgc;
                border-radius: 12px;
                margin-bottom: 16px;
                padding: 16px;
                position: relative;

                .chevron-active {
                    transform: rotate(180deg);
                }

                .select-drop-down {
                    background-color: $hover-color;
                    height: 44px;
                    padding: 10px 12px;
                    border-radius: 12px;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .coin-svg {
                        .svg-icon {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    div {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-size: 15px;
                        font-weight: 800;

                        span {
                            color: $primary-hover;
                            font-size: 13px;
                            font-weight: 700;
                            margin-top: 2px;
                        }
                    }
                }

                .coin-select-options-container {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    top: 69px;
                    z-index: 10;
                    background-color: $hover-color;
                    border: 2px solid $primary-hover;
                    border-radius: 12px;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    gap: 1px;
                    width: 94%;
                    max-width: 512px;

                    .coin-select-option {
                        cursor: pointer;
                        height: 46px;
                        max-width: 90vw;
                        padding: 10px 12px;
                        display: flex;
                        justify-content: space-between;
                        background-color: $main-bgc;
                        transition: all .2s;

                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 8px;
                            transition: all .2s;

                            .coin-select-option-svg {
                                .svg-icon {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            .coin-select-option-title {
                                color: $primary-hover;
                                font-weight: 700;
                                font-size: 15px;
                            }
                        }

                        .active-svg {
                            .svg-icon {
                                width: 20px;
                                height: 20px;

                                path {
                                    fill: $gold-color;
                                }
                            }
                        }


                        &:hover {
                            background-color: #181837;

                            div {
                                .coin-select-option-title {
                                    color: $main-color;
                                }
                            }
                        }

                    }

                    .active {
                        background-color: $hover-color;
                        border-top: 2px solid $primary-hover;
                        border-bottom: 2px solid $primary-hover;

                        .coin-select-option-title {
                            color: $main-color !important;
                        }

                        &:hover {
                            background-color: $hover-color;
                        }
                    }

                    .first {
                        border-top: none;
                    }

                    .last {
                        border-bottom: none;
                    }
                }
            }
        }

        .tags-container {
            display: flex;
            justify-content: end;
            margin-top: 16px;
            gap: 8px;
            font-family: "Roboto", sans-serif;

            .tag-container {
                border-radius: 12px;
                position: relative;

                .tag {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    cursor: text;
                    font-size: .8em;
                    padding: 4px 8px 4px 5px;
                    width: fit-content;
                    font-weight: 800;
                    min-height: 16px;
                    max-height: 24px;
                    position: relative; // Add position relative for tooltip positioning

                    .svg-icon {
                        width: 14px;
                        height: 14px;
                    }
                }

                .tool-tip {
                    display: none;
                    position: absolute;
                    background-color: #7841ee;
                    font-size: .8em;
                    padding: 12px;
                    text-align: center;
                    font-weight: 600;
                    border-radius: 16px;
                    width: 234px;
                    color: white;
                    bottom: calc(100% + 14px);
                    left: 50%;
                    transform: translateX(-50%);
                    visibility: hidden; // Hide initially until positioned

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 100%;
                        top: 99%;
                        left: 50%;
                        transform: translateX(-50%);
                        border-width: 8px;
                        border-style: solid;
                        border-color: #7841ee transparent transparent transparent;
                    }
                }

                &:hover .tool-tip {
                    display: block;
                    visibility: visible; // Show tooltip on hover
                }
            }

            .instant {
                background-color: $gold-color;
                color: $main-darker-bgc;
            }

            .confirm {
                background-color: #7841ee;
            }

            .eth {
                padding-left: 6px;
                background-color: $secondary-hover;
            }
        }

        .address-container {
            background-color: $main-bgc;
            border-radius: 12px;
            margin-bottom: 16px;
            padding: 16px;

            .address-info {
                display: flex;
                font-size: 15px;
                font-weight: 800;
                gap: 12px;

                .qr-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    margin-top: 5px;
                    width: 68px;
                    height: 68px;
                    padding: 4px;
                    background-color: $main-color;
                    cursor: zoom-in;

                    img {
                        width: 64px;
                        height: 64px;
                    }
                }

                .address {
                    font-family: "Roboto", sans-serif;
                    font-size: 12px;
                    color: $text-color;
                    font-weight: 600;
                    word-break: break-all;
                }
            }

            .address-acctions {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                margin-top: 16px;

                button {
                    width: -webkit-fill-available;
                    line-height: 40px;
                    height: 40px;
                }
            }
        }

        .cash {
            background-color: $main-color;

            .svg-container {
                display: flex;
                align-items: center;
                justify-content: center;

                .svg-icon {
                    width: 54px;
                    height: 54px;
                }
            }

            .description {
                color: $main-darker-bgc;
                font-family: "Roboto", sans-serif;
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin: 12px 0px;

                h3 {
                    font-size: 14px;

                    button {
                        border: none;
                        background-color: transparent;
                        font-weight: 700;
                        font-family: "Roboto", sans-serif;
                        font-size: 14px;
                        padding: 0;
                        color: $hover-color;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        .help {
            a {
                color: $text-color;
                transition: all .2s;
                font-weight: 700;
                font-size: 13px;

                &:hover {
                    text-decoration: underline;
                    color: $main-color;
                }
            }
        }


    }

    .bg {
        background-color: rgba(0, 0, 0, 0.651);
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 5;
    }


    .stepOneContainer {
        padding: 28px;
        background-color: #2c2852;
        border: 2px solid $main-darker-bgc;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .stepOneItemsContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        .stepOneItem {
            font-family: "Catamaran", "Roboto", sans-serif;
            display: flex;
            height: 162px;
            min-width: 172px;
            border-radius: 12px;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            border-style: none;
            gap: 4px;
            cursor: pointer;
            background-color: $main-bgc;
            text-align: center;

            &:hover {
                background-color: $secondary-hover;
            }

            .stepOneItem-Short {
                font-size: 17px;
                font-weight: 900;
            }

            .coin-select-option-title {
                text-wrap: wrap;
                max-width: 103px;
                line-height: 18px;
                font-size: 15px;
            }

            .coin-select-option-svg {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 4px;

                svg {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }
}

.select-options-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9;
}

.zoom-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10;
    width: 100vw;

    .zoom-bg {
        background-color: #181837a2;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
    }

    .img-container {
        z-index: 11;
        padding: 16px;
        border-radius: 12px;
        background-color: $main-bgc;

        img {
            padding: 6px;
            border-radius: 5px;
            background-color: $main-color;
        }
    }
}

@media screen and (max-width: 800px) {


    .cashier-container .cashier .cashier-depo-main .coin-select-container .coin-select-options-container .coin-select-option div .coin-select-option-title {
        .bank {
            display: none !important;
        }
    }

    .cashier-container {
        height: calc(100vh - 65px);

        .select-drop-down {
            .bank {
                display: none !important;
            }
        }

        .cashier {
            border: none;
            width: 100vw;
            height: calc(100vh - 65px);
            position: fixed;
            top: 0;
            transform: translate(-50%, 0%);


            .cashier-header {
                background-color: $main-bgc;

                .cashier-header-top {
                    background-color: $main-bgc;
                    padding: 8px 16px 8px 24px;
                    height: 60px;

                    button {
                        background-color: $hover-color;
                        border: 4px solid $main-darker-bgc;
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all .2s;

                        &:hover {
                            background-color: $secondary-hover;
                        }
                    }
                }

                .cashier-header-bootom {
                    background-color: $main-bgc;
                    margin-bottom: 20px;
                }

            }

            .cashier-depo-main {
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                margin: 0px 16px;
            }

            .address-container {
                .address-acctions {
                    flex-wrap: wrap;
                }
            }
        }
    }

    .bg {
        display: none;
    }

}

.cashier-buy {
    background-color: $hover-color;
    margin: 0px 4px;
    padding: 28px;
    padding-bottom: 8px;
    font-family: "Catamaran", "Roboto", sans-serif;

    .header {
        .header-title {
            font-weight: 700;
        }

        .header-subtitle {
            color: $text-color;
            font-size: 13px;
            font-weight: 700;
        }
    }

    .chashier-buy-main {
        background-color: $main-bgc;
        border-radius: 12px;
        margin: 16px 0px;
        padding: 16px;
        position: relative;

        .chashier-buy-select {
            background-color: $hover-color;
            height: 44px;
            padding: 10px 12px;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            div {
                display: flex;
                align-items: center;
                gap: 8px;
                font-size: 15px;
                font-weight: 800;

                span {
                    color: $primary-hover;
                    font-size: 13px;
                    font-weight: 700;
                    margin-top: 2px;
                }
            }

            .chashier-buy-select-svg {
                .svg-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .coin-select-options-container {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 69px;
                z-index: 10;
                background-color: $hover-color;
                border: 2px solid $primary-hover;
                border-radius: 12px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                width: 93%;
                gap: 1px;

                .coin-select-option {
                    cursor: pointer;
                    height: 46px;
                    width: -webkit-fill-available;
                    max-width: 90vw;
                    padding: 10px 12px;
                    display: flex;
                    justify-content: space-between;
                    background-color: $main-bgc;
                    transition: all .2s;

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        transition: all .2s;

                        .coin-select-option-svg {
                            .svg-icon {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        .coin-select-option-title {
                            color: $primary-hover;
                            font-weight: 700;
                            font-size: 15px;
                        }
                    }

                    .active-svg {
                        .svg-icon {
                            width: 20px;
                            height: 20px;

                            path {
                                fill: $gold-color;
                            }
                        }
                    }


                    &:hover {
                        background-color: #181837;

                        div {
                            .coin-select-option-title {
                                color: $main-color;
                            }
                        }
                    }

                }

                .active {
                    background-color: $hover-color;
                    border-top: 2px solid $primary-hover;
                    border-bottom: 2px solid $primary-hover;

                    .coin-select-option-title {
                        color: $main-color !important;
                    }

                    &:hover {
                        background-color: $hover-color;
                    }
                }

                .first {
                    border-top: none;
                }

                .last {
                    border-bottom: none;
                }
            }
        }

        .chevron-active {
            transform: rotate(180deg);
        }

        .input-container {
            position: relative;

            div {
                position: absolute;
                top: 12px;
                left: 12px;
                font-size: 17px;
                font-family: Arial, Helvetica, sans-serif;
            }

            input {
                width: -webkit-fill-available;
                padding-left: 24px;
                font-family: "Roboto", sans-serif;

                &::placeholder {
                    font-family: "Roboto", sans-serif;
                }
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
        }

        form {
            color: $nav-group-svg;
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
            font-size: 13px;
            font-weight: 700;

            label {
                font-size: 15px;
            }
        }
    }
}



.chashier-buy-footer {
    padding: 32px;
    padding-top: 16px;
    background-color: $main-darker-bgc;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .buy-icons-container {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        .buy-icon {
            display: flex;
            justify-content: center;

            .svg-icon {
                width: 51px;
                height: 36px;
            }
        }
    }
}

@media screen and (max-width: 800px) {

    .cashier-container {

        .cashier {
            .cashier-depo-main {
                .coin-select-container {
                    .coin-select-options-container {
                        width: 90.2%;
                        max-width: unset;
                    }
                }
            }
        }

        .cashier-buy {
            border-radius: 12px;
            margin: 0px 16px;

            .chashier-buy-main {
                .chashier-buy-select {
                    .coin-select-options-container {
                        width: 90.2%;
                    }
                }
            }
        }

        .stepOneItemsContainer {
            justify-content: center;
            align-items: center;
            position: relative;
        }

        .stepOneContainer {
            max-width: 100vw;
            margin: 8px;
            border-radius: 12px;
            height: calc(100% - 134px);
            overflow: scroll;
            padding: 8px;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                border: none;
            }

            .stepOneItem {
                min-width: 181px;
            }

        }
    }

    .chashier-buy-footer {
        padding: 16px;
    }
}