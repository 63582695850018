.navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    z-index: 11;
    overflow-y: scroll;
    max-height: 91vh;
    min-height: 91vh;
    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border: none;
    }

    .live-tag{
        background-color: $main-bgc;
        font-family: "Catamaran", "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 800;
        color: $text-color;
        padding: 2px 6px;
        border-radius: 8px;
        margin-left: auto;
        transition: all .2s ease;
    }

    .nav-main-btn-container {
        position: relative;
        left: 5px;
        width: 47px;
        height: 36px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        &:hover {
            background-color: $nav-btn-main-hover;
        }

        &:hover .nav-main-btn {
            background-color: $nav-btn-main-hover;
        }

    }

    .nav-hover-falldown-menu {
        position: absolute;
        height: 0px;
        left: 58px;
        display: none;
        margin-top: -36px;
    }

    .nav-hover-falldown-menu-container {
        font-family: "Catamaran", "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 800;
        color: $text-color;
        background-color: $hover-color;
        min-width: 200px;
        position: absolute;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        display: flex;
        flex-direction: column;
        z-index: 10;

        a,
        div {
            padding: 9px;
            display: flex;
            align-items: center;
            gap: 10px;
            height: 36px;
            transition: all .2s;
            cursor: pointer;

            p {
                margin: 0;
                padding: 0;
            }

            .svg-icon {
                width: 24px;
                height: 24px;

                path {
                    fill: $secondary-color
                }
            }

            &:hover {
                background-color: $secondary-hover;

                .svg-icon {
                    path {
                        fill: $text-color;
                    }
                }
            }
        }

        .first {
            border-top-right-radius: 12px;
        }

        .last {
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
        }
    }

    .home-side-nav-item:hover .nav-hover-falldown-menu {
        display: block;
    }

    .nav-main-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: $secondary-hover;
        border-radius: 12px;
        cursor: pointer;

        &:hover {
            background-color: $nav-btn-main-hover;
        }

    }

    .nav-bar-group-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        background-color: $hover-color;
        border-radius: 12px;

        .first {
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
        }

        .last {
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
        }

        .home-side-nav-item {
            padding: 5px 6px;
            transition: all .2s;

            .svg-icon {
                path {
                    fill: $nav-group-svg;
                }
            }

            .tool-tip {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 0.2s linear;
                font-family: "Catamaran", "Roboto", sans-serif;
                font-size: 13px;
                font-weight: 800;
                position: absolute;
                left: 64px;
                margin-top: -34px;
                padding: 9px;
                border-radius: 8px;
                background-color: $hover-color;
            }

            .tool-tip::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -12px;
                transform: translateY(-50%) rotate(0deg);
                border-width: 6px;
                border-style: solid;
                border-color: transparent $hover-color transparent transparent;
            }


            &:hover {
                .svg-icon {
                    path {
                        fill: $text-color;
                    }
                }

                .tool-tip {
                    visibility: visible;
                    opacity: 1;
                }

                background-color: $secondary-hover;
            }
        }
    }

    .nav-bar-group-container.opened {
        a {
            display: flex;
            gap: 8px;
            align-items: center;
            font-family: "Catamaran", "Roboto", sans-serif;
            font-size: 13px;
            font-weight: 600;
            color: $text-color;
            width: 188px;
            height: 36px;
        }
    }

    .home-side-nav-item.bottom {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background-color: $hover-color;
        cursor: pointer;

        .svg-icon {
            path {
                fill: $main-color
            }
        }

        .tool-tip {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.2s linear;
            font-family: "Catamaran", "Roboto", sans-serif;
            font-size: 13px;
            font-weight: 800;
            position: absolute;
            left: 64px;
            padding: 9px;
            border-radius: 8px;
            background-color: $hover-color;
        }

        .tool-tip::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -12px;
            transform: translateY(-50%) rotate(0deg);
            border-width: 6px;
            border-style: solid;
            border-color: transparent $hover-color transparent transparent;
        }

        &:hover {
            .svg-icon {
                path {
                    fill: $text-color;
                }
            }

            .tool-tip {
                visibility: visible;
                opacity: 1;
            }

            background-color: $secondary-hover;
        }
    }

    .home-side-nav-item.bottom.opened {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        font-family: "Catamaran", "Roboto", sans-serif;
        font-size: 15px;
        font-weight: 700;
        color: $main-color;
        width: 188px;
        height: 36px;
        box-sizing: border-box;
        padding: 6px;

        div {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .chevron-container {
            background-color: $secondary-hover;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            transition: background-color .2s linear;
        }

        &:hover {
            .chevron-container {
                background-color: #887ac2;
            }
        }
    }

    .home-side-nav-item.bottom.opened.lang {
        justify-content: space-between;
        background-color: $hover-color;
        transition: background-color .2s linear;

        &:hover {
            background-color: $secondary-hover;
        }
    }

    .home-side-nav-item.lang {
        background-color: transparent;

        .nav-main-btn {
            background-color: $hover-color;

            &:hover {
                background-color: transparent;
            }
        }

        .nav-hover-falldown-menu {
            div {
                display: flex;
                justify-content: space-between;

                .svg-icon {
                    width: 18px;
                    height: 18px;

                    path {
                        fill: #edaf0e;
                    }
                }
            }

            .active {
                background-color: $lang-active-bg;
                color: $main-color;
                border-bottom: 2px solid black;
            }
        }
    }


    .home-side-nav-item-opened {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .home-side-nav-item-opened-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            background-color: $secondary-hover;
            width: 188px;
            height: 36px;
            border-radius: 12px;
            box-sizing: border-box;
            padding: 6px 6px 6px 8px;
            transition: background-color .2s linear;

            div {
                font-family: "Catamaran", "Roboto", sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 15px;
                font-weight: 800;
            }

            .chevron-container {
                background-color: $chevron-btn-bg;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                transition: background-color .2s linear;
            }

            &:hover {
                background-color: $primary-hover;

                .chevron-container {
                    background-color: #887ac2;
                }
            }

        }

        .home-side-nav-item-opened-btn.active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .chevron-container {
                .svg-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .falldown-menu {
            display: none;
        }

        .falldown-menu.active {
            display: flex;
            flex-direction: column;
            margin-top: 2px;

            a.last {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }

            a {
                font-family: "Catamaran", "Roboto", sans-serif;
                font-size: 13px;
                font-weight: 600;
                color: $text-color;
                width: 188px;
                height: 36px;
                background-color: $hover-color;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 6px 6px 6px 8px;
                gap: 8px;
                transition: all .2s ease;

                .svg-icon {
                    width: 24px;
                    height: 24px;

                    path {
                        fill: $secondary-color
                    }
                }

                &:hover {
                    background-color: $secondary-hover;

                    .svg-icon {
                        path {
                            fill: $text-color;
                        }
                    }
                }
                &:hover .live-tag{
                    background-color: $hover-color;
                }
            }
        }
    }

}


.nav-gap-top,
.nav-gap {
    width: 100vw;
    height: 5px;
    background-color: $main-darker-bgc;
    position: fixed;
    bottom: 60px;
    right: 0px;
    z-index: 999;
}

.nav-gap-top {
    top: 56px !important;
}

.mobile-navbar {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    height: 60px;
    background-color: $main-bgc;
    display: flex;
    align-items: center;
    z-index: 999;

    div,
    a {
        font-family: "Catamaran", "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 800;
        color: $primary-hover;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        cursor: pointer;


        p {
            margin: 0;
        }

        .svg-icon {
            width: 25px;
            height: 24px;

            path {
                fill: $nav-group-svg;
            }
        }
    }

    div.active {
        color: $main-color;

        .svg-icon {
            path {
                fill: $gold-color;
            }
        }
    }
}