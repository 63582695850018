.bonus-card {
    .hallo-img-hand {
        width: 30px;
        height: 60px;
        position: absolute;
        z-index: 5; // Increased z-index to ensure hands are above everything else
    }

    .hallo-img-hand.left {
        top: 31px;
        left: -20px;
    }

    .hallo-img-hand.right {
        top: 282px;
        right: -19px;
    }

    .halloween-card {
        width: 100%;
    }

    .hallo-img-bg {
        position: relative;
        z-index: 2; // Base z-index for backgrounds
        border-radius: 12px;
        background-image: url('../../images/bonus/hallo-hero.jpeg');

        .bg-default {
            background-image: url('../../images/bonus/hallo-hero.jpeg');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
            z-index: 2; // Ensure it’s below the bats
            border-radius: 12px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 25%;
                height: 100%;
                background-image: linear-gradient(to left, rgba(25, 25, 57, 0%), rgba(25, 25, 58, 1));
            }
        }

        .bg-flash {
            background-image: url('../../images/bonus/hallo-effect-bg-img.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0; // Start as hidden
            transition: opacity 0.5s ease-in-out;
            z-index: 1; // Ensure it's below the bats
            border-radius: 12px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 25%;
                height: 100%;
                background-image: linear-gradient(to left, rgba(25, 25, 57, 0%), rgba(25, 25, 58, 1));
            }
        }

        .hallo-img-bat {
            position: relative;
            z-index: 3; // Ensure bats are above both backgrounds
            transition: all 0.2s ease-in-out;
        }

        .hallo-img-bat.one {
            left: 160px;
            width: 80px;
            top: 2%;
        }

        .hallo-img-bat.two {
            left: 260px;
            width: 60px;
            top: 6%;
        }
    }

    .hallo-btn {
        // Button styles remain unchanged
        -webkit-font-smoothing: antialiased;
        text-size-adjust: 100%;
        --roo-left-side-navigation-width: 0;
        --roo-right-side-chat-width: 0;
        --roo-toolbar-height: 56px;
        --roo-bottom-nav-height: 60px;
        scrollbar-color: #463E7A transparent;
        scrollbar-width: thin;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-family: "Catamaran", Roboto, sans-serif;
        font-size: 0.875rem;
        line-height: 1.75;
        min-width: 64px;
        width: 100%;
        text-align: center;
        text-transform: none;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 12px;
        font-weight: 900;
        letter-spacing: normal;
        --roo-drip-bg-transition-time: 0.3s;
        --roo-loading-color: #307001;
        --roo-btn-text-stroke-color: #3E8C00;
        --roo-drip-bg-stroke: #4CAC00;
        --roo-drip-fg: #64E300;
        --roo-drip-spec-highlight: #DBFFBE;
        --roo-drip-fg-dark: #56C400;
        --roo-drip-spec-highlight-dark: #8AFF2F;
        text-shadow: #307001 0px 2px 0px, #307001 0px -1px 0px, #307001 -1px 0px 0px, #307001 1px 0px 0px;
        box-shadow: rgb(76, 172, 0) 0px -3px 0px 0px inset, rgb(138, 255, 47) 0px 2px 0px 0px inset;
        transition: background-size 0.3s ease-in-out;
        background: linear-gradient(420deg, rgb(100, 227, 0) 0%, rgb(100, 227, 0) 15%, rgb(86, 196, 0) 15%, rgb(86, 196, 0) 100%) left center / 100% 100% no-repeat;
        padding: 10px 14px 14px;
        color: rgb(255, 255, 255);

        &::after {
            content: " ";
            position: absolute;
            width: 12px;
            margin-top: 45px;
            margin-left: 85%;
            height: 10px;
            background-color: var(--roo-drip-fg-dark);
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            border-top-right-radius: 20px;
            border-left: #4CAC00 2px solid;
            border-bottom: #4CAC00 2px solid;
            inset: 0px 0px 0px 2.5px;
            transition: background-color .3s ease-in-out;
        }

        &::before {
            content: " ";
            position: absolute;
            width: 12px;
            margin-top: 45px;
            margin-left: 82%;
            height: 22px;
            background-color: var(--roo-drip-fg-dark);
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            border-top-left-radius: 12px;
            border-right: rgb(180, 255, 163) 1px solid;
            inset: 0px 0px 0px 2.5px;
            transition: background-color .3s ease-in-out;
        }

        .extra-objects {
            position: absolute;
            left: 60px;

            &::after {
                content: " ";
                position: absolute;
                width: 8px;
                margin-top: 23px;
                height: 11px;
                background-color: var(--roo-drip-fg-dark);
                border-bottom-left-radius: 40px;
                border-bottom-right-radius: 40px;
                border-top-right-radius: 20px;
                border-right: rgb(180, 255, 163) 2px solid;
                inset: 0px 0px 0px 2.5px;
                transition: background-color .3s ease-in-out;
            }
        }

        &:hover {
            background-color: rgb(86, 196, 0);
            background-size: 750% 100%;
            box-shadow: rgb(76, 172, 0) 0px -3px 0px 0px inset, rgb(138, 255, 47) 0px 2px 0px 0px inset;
            --roo-drip-fg-dark: var(--roo-drip-fg);
            --roo-drip-spec-highlight-dark: var(--roo-drip-spec-highlight);
        }
    }

    // Hover Effects
    &:hover .bg-default {
        opacity: 0; // Fade out the default background
        transition: opacity 0.5s ease-in-out; // Ensure it fades out smoothly
    }

    &:hover .bg-flash {
        opacity: 1; // Fade in the flash background
        transition: opacity 0.5s ease-in-out; // Ensure it fades in smoothly
        animation: flashBackground 2s forwards; // Start the animation
    }

    &:hover .hallo-img-bat.one {
        animation: batFlyLeft .4s ease-in-out 3;
        transform-origin: center;
    }

    &:hover .hallo-img-bat.two {
        animation: batFlyRight .4s ease-in-out 3;
        transform-origin: center;
    }
}

// Keyframes for background flash
@keyframes flashBackground {
    0% {
        opacity: 0; // Start hidden
    }

    50% {
        opacity: 1; // Fully visible
    }

    100% {
        opacity: 0; // Fade back to hidden
    }
}

// Keyframes for bat flying animations
@keyframes batFlyLeft {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    25% {
        transform: translateY(-4px) rotate(-4deg);
    }

    50% {
        transform: translateY(0px) rotate(0deg);
    }

    75% {
        transform: translateY(4px) rotate(4deg);
    }

    100% {
        transform: translateY(0px) rotate(0deg);
    }
}

@keyframes batFlyRight {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    25% {
        transform: translateY(-4px) rotate(4deg);
    }

    50% {
        transform: translateY(0px) rotate(0deg);
    }

    75% {
        transform: translateY(4px) rotate(-4deg);
    }

    100% {
        transform: translateY(0px) rotate(0deg);
    }
}

// Add smoothness to the bat movement
.hallo-img-bat {
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 990px) {
    .bonus-card {
        .hallo-img-hand.left {
            top: -35px;
            left: 33px;
            rotate: 90deg;
        }

        .hallo-img-bat {
            display: none;
        }

        .bg-default {
            &::before {
               opacity: 0;
            }
        }

        .bg-flash {
            &::before {
                opacity: 0;
             }
        }
    }
}