.flash-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .notification {
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  
  .notification.show {
    opacity: 1;
  }
  
  .error {
    background-color: #f44336;
    color: white;
  }
  
  .success {
    background-color: #4caf50;
    color: white;
  }
  