.nav-search {
    position: fixed;
    top: 0;
    right: 0;
    background-color: $main-darker-bgc;
    width: 100vw;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;

    .nav-search-top {
        width: 100%;
        display: flex;
        gap: 10px;

        .nav-search-top-input-container {
            display: flex;
            max-height: 46px !important;
            border: 2px solid $main-bgc;
            border-radius: 12px;
            width: -webkit-fill-available;

            div {
                background-color: $main-bgc;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;

                .svg-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            input {
                width: -webkit-fill-available;
                background-color: $main-bgc;
                font-size: 14px;
                border: none;
                outline: none;
                color: $main-color;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;

                &::placeholder {
                    color: $primary-hover;
                    font-size: 14px;
                    font-weight: 550;
                }
            }

            &:hover {
                border-color: $hover-color;
            }

            &:focus-within {
                border-color: $lang-active-bg;
                /* Change border color when any descendant is focused */
            }
        }


        .close-btn {
            border: none;
            background-color: $main-bgc;
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            transition: all .3s;
            cursor: pointer;

            &:hover {
                background-color: $secondary-hover;
            }
        }
    }

    .nav-search-dropdown {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
        max-height: 38px !important;
        background-color: $main-bgc;
        border-radius: 12px;
        padding: 12px;
        width: -webkit-fill-available;
        cursor: pointer;

        div {
            color: $primary-hover;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                color: $main-color;
            }
        }
    }

    .nav-search-category-container {
        display: flex;
        align-items: center;
        margin-top: 6px;
        gap: 8px;
        width: 100%;
        min-height: 36px;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        .nav-search-category-scroller-left,
        .nav-search-category-scroller {
            position: absolute;
            right: 0;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(to left, rgba(9, 12, 29, 1), rgba(9, 12, 29, 0.01));
            height: 41px;
            cursor: pointer;

            .svg-icon {
                transform: rotate(-90deg);
                position: relative;
                left: 14px;

                path {
                    stroke: $nav-group-svg;
                }
            }

            &:hover {
                .svg-icon {
                    path {
                        stroke: $main-color;
                    }
                }
            }
        }

        .nav-search-category-scroller-left {
            display: none;
            left: 0;
            background: linear-gradient(to right, rgba(9, 12, 29, 1), rgba(9, 12, 29, 0.01));

            .svg-icon {
                transform: rotate(90deg);
                position: relative;
                left: -24px;

                path {
                    stroke: $nav-group-svg;
                }
            }
        }

        .nav-search-category-item {
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            color: $nav-group-svg;
            font-weight: 800;
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: center;
            background-color: $main-bgc;
            border-radius: 12px;
            min-width: 135px;
            min-height: 36px;
            transition: all .2s;

            .svg-icon {
                width: 20px;
                height: 20px;

                path {
                    fill: $nav-group-svg;
                }
            }

            &:hover {
                color: $main-color;
                background-color: $hover-color;

                .svg-icon {
                    path {
                        fill: $gold-color;
                    }
                }
            }
        }
    }

    .nav-search-games-container {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        .nav-search-game-card {
            flex: 1 0 calc(33.33% - 10px);
            margin-bottom: 10px;
            text-align: center;
            color: #8f9098dc;
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            font-weight: 800;
            max-width: 33%;
        }

        .nav-search-game-card-img-container {
            display: block;
            margin-bottom: 4px;
            border-radius: 14px;

            &:hover {
                border: 2px solid $main-color;
            }
        }

        .nav-search-game-card img {
            width: 100%;
            border-radius: 12px;
        }
    }
}

@media screen and (max-width: 570px) {
    .nav-search-game-card {
        max-width: 49% !important;
    }
}

@media screen and (max-width: 382px) {
    .nav-search-game-card {
        max-width: 99% !important;
    }
}