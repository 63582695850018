.bonus-card {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    min-height: 355px;
    background-color: $main-bgc;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    max-width: 1200px;
    margin: 0 auto;

    .bonus-hero {
        width: 60%;
        background-image: url(../../images//bonus/snoop-metavers.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 25%; 
            height: 100%;
            background-image: linear-gradient(to left, rgba(25, 25, 57, 0%), rgba(25, 25, 58, 1));
        }
        
        
        .coins-container {
            position: relative;
            overflow-x: visible;
            overflow-y: clip;
            height: 100%;
            width: 100%;
           
            .coin {
                position: relative;
                width: 80px;
                transition: all .4s;
            }

            .right {
                left: 82%;
                top: 4%;
            }

            .left {
                top: 90px;
                left: -80px;
            }

            .bottom {
                top: 85%;
                right: -380px;
            }
        }
    }

    .roowards{
        background-image: url('../../images/bonus/roowards.jpg');
    }

    &:hover {
        .bonus-hero {
            .coins-container {
                .coin {
                    width: 74px;
                }

                .right {
                    left: 84%;
                    top: -4%;
                }

                .left {
                    top: 72px;
                    left: -90px;
                }

                .bottom {
                    top: 90%;
                    right: -395px;
                }
            }
        }
    }

    .bonus-description {
        width: 40%;
        text-align: center;
        padding: 16px;
        font-family: "Catamaran", "Roboto", sans-serif;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .header {
            font-size: 28px;
            font-weight: 800;
        }

        .text-container {
            color: $secondary-color;
            margin-bottom: auto;
            text-align: left;
        }

        .terms {
            color: $secondary-color;
            font-size: 13px;

            a {
                color: $secondary-color;
                transition: all .2s;

                &:hover {
                    color: $main-color;
                    text-decoration: underline;
                }
            }
        }

    }
}

@media screen and (max-width: 1133px) {
    .bonus-card {
        min-height: 400px;
    }

}

@media screen and (max-width: 990px) {
    .bonus-card {
        flex-direction: column;
        min-height: 600px;
        max-height: 1000px;
        border: 2px solid $hover-color;

        .bonus-hero {
            background-position: center;
            min-height: 340px;
            width: 100%;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%; 
                height: 25%;
                background-image: none;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%; 
                height: 40%;
                background-image: linear-gradient(to bottom, rgba(25, 25, 57, 0%), rgba(25, 25, 58, 1));
            }
        }

        .bonus-description {
            width: 100%;
        }
    }

}

@media screen and (max-width: 886px) {
    .bonus-card {
        flex-direction: column;
        min-height: 660px;

    }
}

@media screen and (max-width: 800px) {
    .bonus-card {
        flex-direction: column;
        min-height: 680px;

    }
}