.two-factor {
    font-family: "Catamaran", "Roboto", sans-serif;
    width: 112%;
    position: relative;
    right: 6%;

    .two-factor-header {
        h2 {
            font-size: 24px !important;
        }

        h3 {
            color: $secondary-color;
            font-size: 15px;
            font-weight: 600;
            margin-top: -10px;
        }
    }

    form {
        .two-factor-inputs-container {
            display: flex;
            max-width: 100%;
            gap: 12px;
            margin-top: 14px;

            input {
                box-sizing: border-box;
                width: calc(16.6% - 10px);
                height: 56px !important;
                text-align: center;
                font-size: 22px;
                font-weight: 800;
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
        }

        .back-btn {
            border: none;
            background-color: transparent;
            color: $main-color;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;
            margin-top: 10px;
            cursor: pointer;
            transition: color .2s;
            font-family: "Catamaran", "Roboto", sans-serif;
            font-size: 13px;
            font-weight: 800;

            .svg-icon {
                transform: rotate(90deg);
                margin-right: 6px;
            }

            &:hover {
                color: $secondary-color;

                .svg-icon {
                    stroke: $secondary-color;

                    path {
                        stroke: $secondary-color;
                    }
                }
            }


        }

        .v2-error-2fa {
            font-size: 12.5px;
            color: #F11F5E;
            text-align: center;
            font-weight: 700;
            margin-bottom: -6px;
            margin-top: -5px;
        }
    }

}

@media screen and (max-width: 800px) {
    .two-factor {
        width: 100%;
        right: 0%;
    }
}