.hero-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 296px;
    border-radius: 12px;
    overflow: hidden;


    .hero-img {
        width: 66.66%;
        background-size: cover;
        background-image: url("../../images/hero.jpg");
        background-repeat: no-repeat;
        background-position: center center;
    }

    .hero-register-container {
        font-family: "Catamaran", Roboto, sans-serif;
        padding: 24px;
        min-width: 318px;
        width: 35.33%;
        background-color: $main-bgc;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            margin: 0 auto;
            font-size: 30px;
            font-weight: 900;
            text-wrap: wrap;
            width: 76%;
            text-align: center;
            letter-spacing: -0.5px;
            line-height: 33px;
            margin-bottom: 8px;
        }

        h3 {
            margin: 0 auto;
            font-size: 18px;
            font-weight: 700;
            color: $secondary-color;
            margin-bottom: 16px;
        }

        a {
            font-family: "Titillium Web", Roboto, sans-serif;
            font-weight: 900;
            font-size: 16px;
            width: 100%;
            margin-bottom: 16px;
        }

        .styled-header {
            font-size: 13px;
            font-weight: 800;
            margin-bottom: 8px;
        }

        .social-btns-continer {
            display: flex;
            width: 100%;
            gap: 15px;
            

            .social-btn-container {
                background-color: $hover-color;
                display: flex;
                justify-content: center;
                align-items: center;
                width: -webkit-fill-available;
                padding: 6px 18px;
                border-radius: 12px;
                gap: 4px;
                font-weight: 800;
                font-size: 13px;
                cursor: pointer;
                min-width: 64px;
                height: 39px;
                transition: background-color .2s;

                .svg-icon {
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin-bottom: 4px;
                }

                &:hover {
                    background-color: $secondary-hover;
                }
            }
        }

    }


}


@media screen and (max-width: 1480px) {
    .hero-container .hero-register-container .social-btns-continer .social-btn-container .social-title {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .hero-container {
        min-height: 355px;
        flex-direction: column;
        height: auto;

        .hero-img {
            width: 100%;
            height: 188px;
        }

        .hero-register-container {
            width: 100%;
            height: auto;
            padding: 12px;

            h2{
                text-wrap: nowrap;
            }

            .styled-btn {
                margin: 0;
            }

            .styled-header,
            .social-btns-continer {
                display: none;
            }
        }
    }
}