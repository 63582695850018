.bet-counter {
    width: 188px;
    height: 56px;
    background-color: $main-darker-bgc;
    border-radius: 12px;
    font-family: "Catamaran", "Roboto", sans-serif;
    
    font-weight: 800;
    padding: 6px 6px 6px 12px;
    display: flex;
    flex-direction: column;
    gap: -2px;
    

    h2 {
        font-size: 13px;
        color: $secondary-text;
        height: 17px;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
    }
}