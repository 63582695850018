.roobet-partners {
    font-family: "Catamaran", "Roboto", sans-serif;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    .header {
        font-size: 28px;
        font-weight: 900;
        margin-bottom: 16px;
    }

    .cards-container {
        display: flex;
        gap: 16px;
        max-width: 100%;

        .card {
            background-color: $main-bgc ;
            display: flex;
            justify-content: space-between;
            gap: 24px;
            height: 104px;
            padding: 28px;
            position: relative;
            border-radius: 12px;
            width: 50%;
            min-width: fit-content;

            text-wrap: nowrap;

            .title {
                span {
                    color: $nav-group-svg;
                    font-size: 13px;
                    font-weight: 700;
                }

                div {
                    font-size: 25px;
                    font-weight: 700;
                }
            }

            .img-container {
                position: relative;
                top: -42px;

                img {
                    width: 130px;
                    height: 130px;
                }
            }
        }
    }

    .opacity-hider {
        background-image: linear-gradient(to right, rgba(9, 12, 29, 0), rgba(9, 12, 29, 1));
        position: absolute;
        height: 136px;
        width: 65px;
        right: 0;
        top: 62px;
        display: none;
    }

}

@media screen and (max-width: 800px) {
    .cards-container {
        overflow-x: scroll;
        height: 145px;
        align-items: center;

        &::-webkit-scrollbar {
            width: 0px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border: none;
        }
    }

    .roobet-partners {
        .opacity-hider {
            display: block;
        }
    }
}