.hero-loggedin {
    width: 100%;
    background-image: url('../../images/hero2.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 12px;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 455px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding: 16px;
    text-shadow: $main-darker-bgc 1px 0 10px;
    div {
        width: 400px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        font-family: "Catamaran", Roboto, sans-serif;
        font-size: 28px;
        font-weight: 900;

        .styled-btn-secondary{
            width: 80px;
        }
    }
}

@media screen and (max-width: 930px) {
    .hero-loggedin {
        min-height: 330px;
    }

}