.loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-bgc;
    z-index: 9999;

    .spinner {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: conic-gradient(#0000 1%, #edae10) content-box;
        mask: repeating-conic-gradient(#0000 0deg, #000 1deg 39deg, #0000 40deg 45deg),
            radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 9px));
        -webkit-mask-composite: destination-in;
        mask-composite: intersect;
        animation: spinner 1s infinite steps(8);
    }

    .roo {
        position: absolute;
        width: 40px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}




@keyframes spinner {
    to {
        transform: rotate(1turn);
    }
}