.main {
    display: flex;
    width: 100vw;



    .home-side-nav-toggle-btn {
        position: relative;
        left: 14px;
        margin-top: 12px;
        margin-bottom: 18px;
        background-color: $hover-color;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 4px solid $main-darker-bgc;

        svg path {
            stroke: $main-color !important;
        }


        &:hover {
            background-color: $secondary-hover;
            cursor: pointer;
        }
    }
}

.home-container {
    width: 100%;
    background-color: $main-bgc;
    height: 100vh;
    overflow: hidden;


    .home-header {
        width: 100%;
        height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;
        max-width: 1248px;
        margin: 0 auto;

        .logo-img {
            width: 170.2px;
        }


        .home-header-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .search-btn {
                background-color: $hover-color;
                border: 4px solid $main-darker-bgc;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
                transition: all .3s;
                cursor: pointer;

                &:hover {
                    background-color: $secondary-hover;
                }
            }

            .home-header-login-container {
                gap: 4px;
                display: flex;
                padding: 4px;
                border-radius: 16px;
                background-color: $main-darker-bgc
            }
        }
    }



    .home-main-content {
        background-color: $main-darker-bgc;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-top-left-radius: 12px;
        padding: 24px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border: none;
        }

        .home-main-content-spacer {
            background-color: $main-darker-bgc;
            min-height: 5px;
            width: 100%;
            position: fixed;
            top: 72px;
            z-index: 5;
        }

        .more-promotions-link {
            text-align: center;
            font-family: "Catamaran", "Roboto", sans-serif;
            color: $nav-group-svg;
            margin: 0 auto;
            margin-bottom: 24px;
            transition: all .2s;
            width: fit-content;
            

            &:hover {
                color: $main-color;
                text-decoration: underline;
            }
        }
    }
}


@media screen and (max-width: 800px) {
    .home-side-nav {
        width: 0px;
        overflow: hidden;
    }

    .home-container {
        .home-header {
            height: 56px;
            padding: 0px 16px;

            .logo-img {
                width: 140.2px;
            }

            .home-header-logo-container {
                max-width: 44px;
                overflow: clip;
            }

            .home-header-actions {
                .search-btn {
                    display: none;
                }
            }
        }


        .home-main-content {
            border-top-left-radius: 0px !important;
            padding: 12px;

            .home-main-content-spacer {
                top: 55px;
                left: 0;
            }
        }

    }
}