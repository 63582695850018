.bonus-card {
    .christmas-ligth {
        z-index: 5;
    }

    .christmas-ligth.top {
        margin-top: -20px;
        margin-right: -23px;
        width: 234px;
        height: 99px;
    }

    .christmas-ligth.bottom {
        margin-bottom: -79px;
        margin-right: 164px;
        width: 434px;
        height: 98px;
    }

    .christmas-img-bg {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        background-image: url('../../images/bonus/christmas-hero.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .christmas-btn-container {
        position: relative;

        .christmas-btn-svg-top {
            position: absolute;
            width: 80px;
            top: -3px;
            right: -3px;
        }

        .christmas-btn-svg-bottom {
            position: absolute;
            width: 80px;
            top: 7px;
            left: 0px;
        }
    }

}

@media screen and (max-width: 990px) {
    .bonus-card {
        .christmas-ligth.bottom {
            display: none;
        }

        .christmas-img-bg {
            border-top-left-radius: 12px;
        }
    }
}