.rank-container {
    .progress-border {
        width: 34px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 12px;

        .img-container {
            width: 34px;
            height: 24px;
        }

        img {
            width: 34px;
            height: 24px;
            border-radius: 12px;
        }
    }
}

.rank-type {
    position: absolute;
    font-family: "Catamaran", "Roboto", sans-serif;
    left: 46px;
    top: 20px;
    font-size: 13px;
    font-weight: 700;
    color: $gold-color;
    display: none;
}

.progress-bar {
    background-color: $main-bgc;
    height: 8px;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    top: 25px;
    width: 90px;
    left: 46px;

    .progress-fill{
        background-color: $gold-color;
    }
}

.user-name-out {
    position: absolute;
    font-family: "Catamaran", "Roboto", sans-serif;
    left: 46px;
    top: 5px;
    width: 93px;
    height: 36px;
    font-size: 13px;
    font-weight: 800;
    overflow: hidden;
}

.chevron {
    position: absolute;
    left: 140px;
    top: 12px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
}


@media screen and (max-width: 800px) {

    .progress-bar{
        display: none;
    }

    .chevron,
    .rank-type,
    .user-name-out {
        display: none;
    }

    .progress-border {
        width: 36px !important;
        height: 36px !important;

        .img-container {
            width: 28px !important;
            height: 18px !important;
        }

        img {
            width: 28px !important;
            height: 18px !important;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            padding: 2px;
            /* Space for the border */
            background:
                conic-gradient($rank-border calc(var(--progress) * 1%),
                    transparent 0 100%);
            -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
            mask-composite: exclude;
            -webkit-mask-composite: destination-out;
            box-sizing: border-box;
        }
    }

}