.nav-menu {
    background-color: $main-bgc;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 12px;
    border-radius: 12px;
    margin-bottom: 120px;


    .navbar {
        width: -webkit-fill-available;
        overflow-y: scroll;
        max-height: 91vh;
        &::-webkit-scrollbar {
            width: 0px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border: none;
        }

        .bet-counter,
        .home-side-nav-item.bottom.opened,
        .nav-bar-group-container,
        .home-side-nav-item-opened,
        .home-side-nav-item-opened-btn {
            width: -webkit-fill-available;
            

            .falldown-menu.active a {
                width: -webkit-fill-available;
            }
        }

        .nav-bar-group-container.opened a {
            width: -webkit-fill-available;
        }
    }

}