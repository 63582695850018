.copyright {
    margin-top: 36px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
    color: $nav-group-svg;
    font-family: "Catamaran", "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;

    img {
        width: 123px;
    }
}

@media screen and (max-width: 800px) {
    .copyright {
        margin-top: 24px;
    }

}